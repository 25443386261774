import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, share } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor() { }
  
  private cache: Map<HttpRequest<unknown>, HttpResponse<unknown>> = new Map()

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    if (request.method !== "GET") {
      return next.handle(request)
    }
    const cachedResponse: HttpResponse<unknown> = this.cache.get(request)    
    if (cachedResponse) {
      return of(cachedResponse.clone())
    }
    else {
      return next.handle(request).pipe(
          tap(stateEvent => {
        if (stateEvent instanceof HttpResponse<unknown>) {          
          this.cache.set(request, stateEvent.clone())          
        }
      }), 
      shareReplay()
      );
    }
  }
}
