import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filters'
})
export class FiltersPipe implements PipeTransform {
  
  transform(array: any[], searchQuery: string): any[] {
    if (!array || !searchQuery) {
      return array;
    }

    // Convert search query to lowercase to make search case-insensitive
    searchQuery = searchQuery.toLowerCase();

    // Filter the array based on the search query
    return array.filter((item) => {
      // Check if any property in the item contains the search query
      for (let prop in item) {
        if (typeof item[prop] === 'string' && item[prop].toLowerCase().includes(searchQuery)) {
          return true;
        }
        else if (Array.isArray(item[prop])) {
          // If property is an array, search in each object inside the array
          for (let i = 0; i < item[prop].length; i++) {
            if (this.transform([item[prop][i]], searchQuery).length > 0) {
              return true;
            }
            else if (typeof item[prop][i] === 'object') {
              // If item is an object, search recursively
              for (let nestedProp in item[prop][i]) {
                if (typeof item[prop][i][nestedProp] === 'string' && item[prop][i][nestedProp].toLowerCase().includes(searchQuery)) {
                  return true;
                }
                else if (Array.isArray(item[prop][i][nestedProp])) {
                  // If nested property is an array, search in each object inside the nested array
                  for (let j = 0; j < item[prop][i][nestedProp].length; j++) {                    
                    if (this.transform([item[prop][i][nestedProp]], searchQuery).length > 0) {
                      return true;
                    }
                  }
                }
              }
            }
          }
        }
        else if (typeof item[prop] === 'object') {
          // If property is an object, search recursively
          if (this.transform([item[prop]], searchQuery).length > 0) {
            return true;
          }
        }
      }

      return false;
    });
  }
}
