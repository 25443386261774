import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[matInput]',
})
export class DisableSpellCheckDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const matInputElements: HTMLInputElement[] = this.el.nativeElement.querySelectorAll('input[matInput]');
    const matTextareaElements: HTMLTextAreaElement[] = this.el.nativeElement.querySelectorAll('textarea[matInput]');

    matInputElements.forEach((input) => {
      this.disableSpellCheck(input);
    });

    matTextareaElements.forEach((textarea) => {
      this.disableSpellCheck(textarea);
    });
  }

  private disableSpellCheck(element: HTMLInputElement | HTMLTextAreaElement) {
    if (element) {
      element.spellcheck = false;
    }
  }
}
