import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../app/shared/services/auth.service'
import { MessageService } from '../shared/services/message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService,private message: MessageService) {
    
  }

  ngOnInit(): any {        
    this.getLoggedInUserDetails();
  }
  
  getLoggedInUserDetails() {
    this.authService.getLoggedInUserDetails().subscribe({
      next:(response) => {    
        this.authService.login(response.Response.LoggedUserDetails);
        if(this.authService.getUserRole() != null || this.authService.getUserRole() != undefined || this.authService.getUserRole() != '')
          this.authService.setUserRole(this.authService.getUserRole());
        else
          this.authService.setUserRole(response.Response.LoggedUserDetails.Roles[0]);
        
        response.Response.LoggedUserDetails.Roles.forEach((element) => {
          this.authService.setUserForecasts(element,  response.Response.LoggedUserDetails.RoleDetails[element]);
        })
        this.router.navigate(['../pages'], { relativeTo: this.activatedRoute });
      },
      error: (errorResponse) => {
        this.authService.logout();
        window.location.href = environment.idmLogoffUrl;
        console.error(errorResponse);
      }
    });
  }
}
