import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject, Observable } from 'rxjs';


interface alertData {
  alertMessage: string;
  alertType: string;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) {}
  stringifiedData: any;  
  parsedJson: any;  
  private subject = new Subject<alertData>();
  private selectForecast = new Subject<any>();
  private updateForecastFlag = new Subject<any>();
  sendMessage(message){
    this.subject.next(message);
    
  }

  recievedMessage() : Observable<alertData> {
    
    return this.subject.asObservable();
  }

  sendForecastId(forecastId){
    
    this.selectForecast.next(forecastId);
  }
  
  recieveForecastId(){
    return this.selectForecast.asObservable();
  }

  updateForecastCall(call){
    
    this.updateForecastFlag.next(call);
  }

  recieveForecastCall(){
    return this.updateForecastFlag.asObservable()
  }
}
